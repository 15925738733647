<template>
  <div>
    <b-row>
      <b-col md="4">
        <div class="h-100">
          <b-row>
            <b-col
              cols="10"
            >
              <div v-show="!showBillingPanel">
                <!-- If doesn't selected payment method -->
                <div v-if="!isThePaymentMethodSelected">
                  <h4>Válasszon fizetési módot</h4>
                  <div class="paymentTypesContainer">
                    <div
                      class="paymentCard"
                      @click="setPaymentType('cash')"
                    >
                      <img
                        :src="require('../../assets/images/cash.png')"
                        alt=""
                      >
                      <div class="title">
                        Készpénz
                      </div>
                    </div>
                    <div
                      class="paymentCard"
                      @click="setPaymentType('bankcard')"
                    >
                      <img
                        :src="require('../../assets/images/credit-cards.png')"
                        alt=""
                      >
                      <div class="title">
                        Bankkártya
                      </div>
                    </div>
                  </div>
                </div>
                <!-- If payment method is selected -->
                <div v-else>
                  <b-button @click="setPaymentType(null)">
                    Másik fizetési módot választok
                  </b-button>
                  <div v-if="paymentTypeIsCard">
                    <div v-if="!isPaid">
                      <h4>A tranzakció befejezéséhez használja a terminált!</h4>
                      <p>Sikeres tranzakció után, kérem kattintson a fizetve gombra!</p>
                    </div>
                    <div v-else>
                      <h4>Tranzakció megjelölve fizetettként!</h4>
                      <p>Számla létrehozásához kattintson a számlázás gombra!</p>
                    </div>
                  </div>
                  <div>
                    <b-button
                      v-if="!this.isPaid"
                      variant="success"
                      @click="cashPaymentConfirm"
                    >
                      Fizetve
                    </b-button>
                  </div>
                </div>
                <b-button
                  v-show="isPaid"
                  variant="success"
                  @click="toggleBillingPanel"
                >
                  Számlázás
                </b-button>
              </div>
              <!-- Billing process panel -->
              <div v-show="showBillingPanel">
                <Billing ref="billing" />
              </div>
              <b-button
                v-show="isPaid"
                variant="success"
                style="margin-top: 20px;"
                @click="finishPayment"
              >
                Eladás véglegesítése
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Billing from '@/layouts/components/products/Billing'

export default {
  name: 'SaleCheckout',
  components: {
    Billing,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapGetters([
      'getBillingPanelVisibility',
      'isHasBill',
      'getAllOrdersProducts',
      'getSumPrice',
      'getVat',
      'getBillingName',
      'getBillingEmail',
      'getBillingPostCode',
      'getBillingAddress',
      'getBillingCity',
      'getPaymentType',
      'isPaid',
      'getBillId',
      'getStornoBillId',
      'getWarehouseId',
    ]),
    ...mapState(['payment', 'order', 'billing']),
    amountToBePaid() {
      return this.getSumPrice + this.getVat
    },
    change() {
      return this.cash - this.amountToBePaid
    },

    isThePaymentMethodSelected() {
      return this.getPaymentType !== null
    },
    paymentTypeIsCash() {
      return this.getPaymentType === 'cash'
    },
    paymentTypeIsCard() {
      return this.getPaymentType === 'bankcard'
    },

    showBillingPanel() {
      return this.getBillingPanelVisibility || this.isHasBill
    },
  },
  created() {
    if (this.getAllOrdersProducts.length === 0) {
      this.$router.push({ name: 'sale' })
    }
  },
  methods: {
    async paymentStartReject() {
      await this.setCash(0)
      await this.$store.dispatch('startPayment', false)
      await this.$store.dispatch('resetPaymentState')
      await this.$store.dispatch('resetBillingState')
      this.$refs.keypad.clear()
    },
    async finishPayment() {
      const products = this.getAllOrdersProducts.map(item => ({
        product_id: item.product.id,
        qty: item.qty,
      }))
      await this.$store.dispatch('closePayment', { products, payment_type: this.getPaymentType, warehouse_id: this.getWarehouseId })
      await this.$store.dispatch('resetPaymentState')
      await this.$store.dispatch('resetBillingState')
      await this.$store.dispatch('resetOrderState')
      await this.$router.push({ name: 'sale' })
    },

    toggleBillingPanel() {
      this.$store.dispatch('showBillingPanel', true)
    },

    setPaymentType(value) {
      this.$store.dispatch('setPaymentType', value)
      this.$store.dispatch('setPaymentMethod', value)
      if (value === null) {
        this.$store.dispatch('setIsPaid', false)
      }
    },

    setCash(value) {
      this.cash = value
      if (this.isPaid === true) {
        this.$store.dispatch('setIsPaid', false)
      }
    },
    cashPaymentConfirm() {
      this.$store.dispatch('setIsPaid', true)
    },
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: visible!important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.paymentCard{
  display:inline-flex;
  flex-direction: column;
  width: 200px;
  height: 100px;
  margin: 5px;
  border-radius: 4px;
  background-color: rgba(0,0,0,.1);
  justify-content: center;
  align-items: center;
  cursor:pointer;
  font-weight: bolder;
  img{
    width: 50px;
    height: auto;
    display:block;
    padding-bottom:10px;
  }
}
.finishPayment{
  padding: 10px;
  background-color: #245d15;
  margin: 10px;
}
</style>
