<template>
  <div id="billingPanel">
    <div
      v-if="!isHasBill"
      id="createBill"
    >
      <div class="billingDetails">
        <h3>Számla létrehozása</h3>
        <div class="inputs">
          <!-- Name -->
          <b-form-group id="name">
            <label for="name">Név</label>
            <b-form-input
              id="name"
              v-model="billingName"
              type="text"
              name="name"
            />
          </b-form-group>
          <!-- ZIP Code -->
          <b-form-group id="zip">
            <label for="post_code">Irányítószám</label>
            <b-form-input
              id="post_code"
              v-model="billingPostCode"
              type="number"
              name="post_code"
            />
          </b-form-group>
          <!-- City -->
          <b-form-group id="city">
            <label for="city">Város</label>
            <b-form-input
              id="city"
              v-model="billingCity"
              type="text"
              name="city"
            />
          </b-form-group>
          <!-- Address -->
          <b-form-group id="address">
            <label for="address">Utcanév, házszám</label>
            <b-form-input
              id="address"
              v-model="billingAddress"
              type="text"
              name="address"
            />
          </b-form-group>
          <!-- Email -->
          <b-form-group id="email">
            <label for="email">Email cím</label>
            <b-form-input
              id="email"
              v-model="billingEmail"
              type="email"
              name="email"
            />
          </b-form-group>
          <!-- Tax number -->
          <b-form-group id="tax_number">
            <label for="tax_number">Adószám</label>
            <b-form-input
              id="tax_number"
              v-model="billingTaxNumber"
              type="text"
              name="tax_number"
            />
          </b-form-group>
        </div>
      </div>
      <div class="buttons">
        <b-button @click="closePanel">
          Mégse
        </b-button>
        <b-button
          variant="success"
          @click="createBill"
        >
          Számla kiállítása
        </b-button>
      </div>
    </div>

    <div
      v-else
      id="hasBill"
    >
      <div class="billingPreviews">
        <a
          :href="getBillPublicUrl"
          target="_blank"
        >
          <span class="icon">
            <img
              :src="require('../../../assets/images/bill.png')"
              alt=""
            >
          </span>
          <span class="title">Számlaaaa</span>
        </a>
        <a
          v-if="getStornoBillId !== null"
          :href="getStornoPublicUrl"
          target="_blank"
          class="bill"
        >
          <span class="icon">
            <img
              :src="require('../../../assets/images/storno-bill.png')"
              alt=""
            >
          </span>
          <span class="title">Sztornó számla</span>
        </a>
      </div>
      <b-button
        v-if="getStornoBillId === null"
        variant="danger"
        @click.prevent="cancelBill"
      >
        Számla sztornózása
      </b-button>

      <div
        v-show="getBillPublicUrl !== null && getStornoPublicUrl !== null"
        id="newRound"
      >
        <b-button @click="closePanel">
          Nincs számlára
        </b-button>
        <b-button
          variant="success"
          @click="resetBillingProcess"
        >
          Új számla kiállítása
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import {
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Billing',
  components: {
    BFormGroup,
    BFormInput,
    BButton,
  },
  computed: {
    ...mapState(['billing', 'order']),
    ...mapGetters([
      'isHasBill',
      'getBillId',
      'getStornoBillId',
      'getBillingDetails',
      'getBillingName',
      'getBillingTaxNumber',
      'getBillingEmail',
      'getBillingPostCode',
      'getBillingAddress',
      'getBillingCity',
      'getAllOrdersProducts',
      'getBillPublicUrl',
      'getStornoPublicUrl',
    ]),

    billingName: {
      get() {
        return this.getBillingName
      },
      set: _.debounce(function (billingName) {
        this.$store.dispatch('setBillingName', billingName)
      }, 300),
    },
    billingEmail: {
      get() {
        const emails = this.getBillingEmail
        return emails.length > 0 ? emails[0] : ''
      },
      set: _.debounce(function (billingEmail) {
        this.$store.dispatch('setBillingEmail', billingEmail)
      }, 300),
    },
    billingPostCode: {
      get() {
        return this.getBillingPostCode
      },
      set: _.debounce(function (billingPostCode) {
        this.$store.dispatch('setBillingPostCode', billingPostCode)
      }, 300),
    },
    billingAddress: {
      get() {
        return this.getBillingAddress
      },
      set: _.debounce(function (billingAddress) {
        this.$store.dispatch('setBillingAddress', billingAddress)
      }, 300),
    },
    billingCity: {
      get() {
        return this.getBillingCity
      },
      set: _.debounce(function (billingCity) {
        this.$store.dispatch('setBillingCity', billingCity)
      }, 300),
    },
    billingTaxNumber: {
      get() {
        return this.getBillingTaxNumber
      },
      set: _.debounce(function (billingTaxNumber) {
        this.$store.dispatch('setBillingTaxNumber', billingTaxNumber)
      }, 300),
    },
  },
  methods: {
    closePanel() {
      this.$store.dispatch('resetBillingState')
    },
    async createBill() {
      const serverResponse = await this.$store.dispatch('createBill', { orderedProducts: this.getAllOrdersProducts })
      if (serverResponse.status === 201) {
        await this.$store.dispatch('setBillId', serverResponse.data.data.id)
        await this.$store.dispatch('setHasBill', true)
        await this.$store.dispatch('setBillPublicUrl', serverResponse.data.data.id)
      } else {
        // console.log('HIBA MIATT NEM JÖTT LÉTRE A SZÁMLA!')
      }
    },
    async cancelBill() {
      const serverResponse = await this.$store.dispatch('cancelBill')
      if (serverResponse.status === 200) {
        await this.$store.dispatch('setStornoBillId', serverResponse.data.data.id)
        await this.$store.dispatch('setStornoPublicUrl', serverResponse.data.data.id)
      } else {
        // console.log('HIBA MIATT NEM SIKERÜLT TÖRÖLNI A SZÁMLÁT')
      }
    },
    async resetBillingProcess() {
      await this.$store.dispatch('resetBillingState')
      await this.$store.dispatch('showBillingPanel', true)
    },

    async paymentStartReject() {
      await this.setCash(0)
      await this.$store.dispatch('startPayment', false)
      await this.$store.dispatch('resetPaymentState')
      await this.$store.dispatch('resetBillingState')
      this.$refs.keypad.clear()
    },
    async finishPayment() {
      const products = this.getAllOrdersProducts.map(item => ({
        product_id: item.product.id,
        qty: item.qty,
      }))
      await this.$store.dispatch('closePayment', { products, payment_type: this.getPaymentType })
      await this.$store.dispatch('resetPaymentState')
      await this.$store.dispatch('resetBillingState')
      await this.$store.dispatch('resetOrderState')
      await this.setCash(0)
      await this.$refs.keypad.clear()
    },

    toggleBillingPanel() {
      this.$store.dispatch('showBillingPanel', true)
    },

    setPaymentType(value) {
      this.$store.dispatch('setPaymentType', value)
      this.$store.dispatch('setPaymentMethod', value)
      if (value === null) {
        this.$store.dispatch('setIsPaid', false)
      }
    },

    setCash(value) {
      this.cash = value
      if (this.isPaid === true) {
        this.$store.dispatch('setIsPaid', false)
      }
    },
    cashPaymentConfirm() {
      if (this.getPaymentType === 'cash' && this.cash >= this.amountToBePaid) {
        this.$store.dispatch('setIsPaid', true)
      }
      if (this.getPaymentType === 'bankcard') {
        this.$store.dispatch('setIsPaid', true)
      }
    },
  },
}
</script>

<style lang="scss">
.billingPreviews{

  a{
    display:inline-flex;
    flex-direction: column;
    width: 200px;
    height: 100px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(0,0,0,.1);
    justify-content: center;
    align-items: center;
    cursor:pointer;
    font-weight: bolder;
  }
  img{
    width: 50px;
    height: auto;
    display:block;
    padding-bottom:10px;
  }
}
</style>
